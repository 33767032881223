import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCheckbox, MDBCollapse, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import Pager from './pager';
import Select, { OptionType } from './select';
import { I18N } from '../util/i18n';
import InputBox from './input-box';
import { ListResponse } from '../api/types';
import { CancelablePromise } from '../util/client';
import { useNavigate } from 'react-router-dom';

interface Column {
    label: string;
    editable?: (item: any, ref: RowRef, e: React.FocusEvent<HTMLTableCellElement>) => void;
    type?: React.HTMLInputTypeAttribute | 'select';
    filterable?: string;
    sortable?: string;
    format?: (value: any, item: Record<string, any>, ref: RowRef) => string | JSX.Element;
    hidden?: boolean;
    className?: string;
    emptyOption?: string;
    load?: () => Promise<Array<OptionType>>;
    options?: Array<OptionType>;
}

export type Columns = { [key: string]: Column };

type FilterProps = {
    columns: Columns;
    renderBtns?: (search: URLSearchParams) => JSX.Element | null;
    additionalFilter?: (search: URLSearchParams) => JSX.Element | null;
}

function Filter(props: FilterProps & { params: URLSearchParams, onSearch: (params: URLSearchParams) => void }) {
    let [status, setStatus] = React.useState(false);
    let [data, setData] = React.useState<Record<string, any>>({});
    const onSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(e => {
        e.stopPropagation();
        e.preventDefault();
        let fd = new FormData(e.target as HTMLFormElement);
        let params = (new URL(window.location.href)).searchParams;
        for (const [k, v] of fd.entries()) {
            params.set(k, v as string);
        }
        params.set('page', '1');
        setData(params);
        props.onSearch(params);
    }, [props.params]);
    const onReset = React.useCallback<React.FormEventHandler<HTMLFormElement>>(e => {
        e.stopPropagation();
        e.preventDefault();
        props.onSearch(new URLSearchParams());
        setData({});
    }, [props.params]);
    const onChange = React.useCallback<{ (e: React.ChangeEvent<HTMLInputElement>): void; (e: string, v: string): void }>((e: React.ChangeEvent<HTMLInputElement> | string, v?: string) => {
        if (typeof e === 'string') {
            setData({ ...data, [e]: v });
        } else {
            e.stopPropagation();
            e.preventDefault();
            setData({ ...data, [e.target.name]: e.target.value });
        }
    }, [data]);
    let keys = React.useMemo(() => {
        let result = [];
        for (let k in props.columns) {
            if (props.columns[k].filterable) {
                result.push(k);
            }
        }
        return result;
    }, [props.columns]);
    return (
        <>
            <MDBCollapse open={status} className="filter">
                <form onSubmit={onSubmit} onReset={onReset}>
                    {keys.map(key => {
                        let name = props.columns[key].filterable || key;
                        switch (props.columns[key].type) {
                            case 'select':
                                return (
                                    <Select key={key} name={name} value={data[name] || ''}
                                        options={props.columns[key].options}
                                        onChange={onChange.bind(null, key)}
                                        label={props.columns[key].label}
                                        load={props.columns[key].load}>
                                        <option value="">{props.columns[key].emptyOption || ''}</option>
                                    </Select>
                                );
                            default:
                                return (
                                    <InputBox key={key} label={props.columns[key].label}>
                                        <input type={props.columns[key].type || 'text'}
                                            className="form-control active" name={name}
                                            value={data[name] || ''} onChange={onChange} />
                                    </InputBox>
                                );
                        }
                    })}
                    {typeof props.additionalFilter === 'function' ? props.additionalFilter(props.params) : props.additionalFilter}
                    <MDBBtn type="submit"><I18N>Search</I18N></MDBBtn>
                    <MDBBtn type="reset" color="warning"><I18N>Reset</I18N></MDBBtn>
                    {typeof props.renderBtns === 'function' ? props.renderBtns(props.params) : props.renderBtns}
                </form>
            </MDBCollapse>
            <MDBBtn onClick={setStatus.bind(null, !status)} className="filter-toggle d-flex d-md-none">
                <span>
                    <span className="fa fa-fw fa-filter me-2" />
                    <I18N>Filter</I18N>
                </span>
                <span className={'fa fa-fw fa-caret-' + (status ? 'up' : 'down')} />
            </MDBBtn>
        </>
    );
}

type SorterProps = {
    columns: Columns;
    checkable?: (id: number | string) => Record<string, any>;
    onSort?: (search: URLSearchParams) => void;
}

function Sorter(props: SorterProps & { params: URLSearchParams, onSort: (params: URLSearchParams) => void, actions?: boolean, multiActions?: boolean }) {
    const onClick = React.useCallback((name: string, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let params = new URLSearchParams;
        if (props.params.get('sort') === name + ' DESC') {
            params.set('sort', name + ' ASC');
        } else {
            params.set('sort', name + ' DESC');
        }
        props.onSort && props.onSort(params);
    }, [props.params]);
    return (
        <tr>
            {props.multiActions || props.checkable ? (
                <th className="check">
                    <MDBCheckbox />
                </th>
            ) : null}
            {Object.keys(props.columns).map(key => {
                if (props.columns[key].hidden) {
                    return null;
                }
                let name = props.columns[key].sortable || key;
                return (
                    <th key={key} className={'text-nowrap ' + (props.columns[key].className || '')}>
                        {props.columns[key].sortable ? (
                            <MDBBtn color="link" onClick={onClick.bind(null, name)}>
                                {props.columns[key].label}
                                {props.params.get('sort') === name + ' DESC' ? (<span className="fa fa-caret-down ms-1" />) : (props.params.get('sort')?.startsWith(name) ? (<span className="fa fa-caret-up ms-1" />) : null)}
                            </MDBBtn>
                        ) : props.columns[key].label}
                    </th>
                );
            })}
            {props.actions ? (
                <th className="action text-center"><I18N>Action</I18N></th>
            ) : null}
        </tr>
    );
}

interface RowRef {
    remove: () => void;
    redraw: (item: Record<string, any>) => void;
}

export type Actions = Array<{
    label: string;
    onClick: (item: any, ref: RowRef, e: React.MouseEvent<HTMLElement>) => void;
    condition?: (item: Record<string, any>) => boolean
}>;

type RowProps = {
    columns: Columns;
    checkable?: (id: number | string) => Record<string, any>;
    rowAttr?: (item: Record<string, any>) => Record<string, any>;
    id?: string;
    onClickRow?: React.MouseEventHandler<HTMLTableRowElement>;
    actions?: Actions;
    multiActions?: Array<{}>;
};

const Row = React.forwardRef<RowRef, RowProps & { item: Record<string, any> }>(function (props, ref) {
    let [item, setItem] = React.useState<Record<string, any>>(props.item);
    let [removed, setRemoved] = React.useState(false);
    let handler = React.useMemo<RowRef>(() => ({
        remove: () => setRemoved(true),
        redraw: (item: Record<string, any>) => setItem(item)
    }), [removed, item]);
    React.useImperativeHandle(ref, () => handler, [handler]);
    return (
        <tr hidden={removed} className="align-middle" onClick={e => props.onClickRow && props.onClickRow(e)} {...(props.rowAttr ? props.rowAttr(item) : {})}>
            {props.multiActions?.length || props.checkable ? (
                <th className="check">
                    <MDBCheckbox value={item[props.id || 'id']} {...(props.checkable ? props.checkable(item[props.id || 'id']) : {})} />
                </th>
            ) : null}
            {Object.keys(props.columns || {}).map(key => {
                if (props.columns[key].hidden) {
                    return null;
                }
                let value = props.columns[key].format?.(item[key], item, handler) || item[key];
                return (
                    <td key={key} className={props.columns[key].className}
                        contentEditable={props.columns[key].editable ? true : undefined}
                        onBlur={props.columns[key].editable?.bind(null, item, handler)}
                        onFocus={props.columns[key].editable ? e => e.target.innerText = (typeof value === 'string' ? value : (item[key] || '')) : undefined}>
                        {value}
                    </td>
                );
            })}
            {props.actions?.length ? (
                <td className="action">
                    {props.actions.length > 1 ? (
                        <MDBDropdown>
                            <MDBDropdownToggle size="sm"><I18N>Action</I18N></MDBDropdownToggle>
                            {(() => {
                                let children = [];
                                for (let i in props.actions) {
                                    if (typeof props.actions[i].condition !== 'function' || props.actions[i].condition!(item)) {
                                        children.push(React.createElement(MDBDropdownItem, {
                                            key: 'a-' + i,
                                            link: true,
                                            onClick: props.actions[i].onClick.bind(null, item, handler)
                                        }, props.actions[i].label));
                                    }
                                }
                                return React.createElement(MDBDropdownMenu, null, ...children);
                            })()}
                        </MDBDropdown>
                    ) : (
                        <MDBBtn size="sm" type="button" onClick={props.actions[0].onClick.bind(null, item, handler)}>{props.actions[0].label}</MDBBtn>
                    )}
                </td>
            ) : null}
        </tr>
    );
});

type GridProps = SorterProps & FilterProps & RowProps & {
    doLoad?: (params: Record<string, any>) => CancelablePromise<ListResponse<any> | string>;
    limit?: number;
    query: URLSearchParams;
    setQuery: (search: URLSearchParams) => void;
    newUrl?: string | ((data: Array<Record<string, any>>, setData: React.Dispatch<React.SetStateAction<Array<Record<string, any>>>>) => void);
}

export interface GridRef {
    reload: (params?: URLSearchParams) => void;
}

export default React.forwardRef<GridRef, GridProps>(function Grid(props, ref) {
    let [data, setData] = React.useState<Array<Record<string, any>>>([]);
    let [total, setTotal] = React.useState(0);
    let navigate = useNavigate();
    const debounce = React.useRef<CancelablePromise<any> | null>(null);
    const code = React.useRef('');
    const load = React.useRef((params: URLSearchParams) => {
        if (!props.doLoad) {
            return;
        }
        let tmp: Record<string, any> = {}, c = '';
        for (const [k, v] of params.entries()) {
            tmp[k] = v;
            c += '&' + k + '=' + v;
        }
        if (debounce.current) {
            if (code.current === c) {
                return;
            }
            debounce.current.cancel();
        }
        code.current = c;
        props.setQuery(params);
        (debounce.current = props.doLoad(tmp)).then(response => {
            if (typeof response !== 'string') {
                setData(response.data);
                setTotal(response.total);
            }
            debounce.current = null;
        });
    });
    React.useEffect(() => {
        load.current(props.query);
    }, [props.query]);
    React.useImperativeHandle(ref, () => ({
        reload: params => load.current(params || props.query)
    }), [props.query]);
    let filterable = React.useMemo(() => {
        for (let key in (props.columns || {})) {
            if (props.columns[key].filterable) {
                return true;
            }
        }
        return false;
    }, [props.columns]);
    React.useEffect(() => {
        if (props.newUrl) {
            let l = () => {
                if (typeof props.newUrl === 'string') {
                    navigate(props.newUrl);
                } else {
                    props.newUrl?.(data, setData);
                }
            };
            document.addEventListener('new', l);
            return () => {
                document.removeEventListener('new', l);
            };
        }
    }, [props.newUrl, navigate, data, setData]);
    return (
        <div className="grid" data-new-url={props.newUrl ? '1' : undefined}>
            {filterable || props.renderBtns || props.additionalFilter ? (
                <Filter columns={props.columns || {}} params={props.query} onSearch={load.current} additionalFilter={props.additionalFilter} renderBtns={props.renderBtns} />
            ) : null}
            <MDBCard className="grid-content my-3">
                <MDBTable hover responsive className="mb-0">
                    <MDBTableHead>
                        <Sorter columns={props.columns || {}} params={props.query} onSort={load.current} actions={Boolean(props.actions?.length)} multiActions={Boolean(props.multiActions?.length)} checkable={props.checkable} />
                    </MDBTableHead>
                    <MDBTableBody>
                        {data.length ? data.map((item, index) => (
                            <Row key={index + '-data-' + (item.id || JSON.stringify(item))} item={item} {...props} />
                        )) : (
                            <tr>
                                <td colSpan={Object.keys(props.columns).length + 1} className="empty">
                                    <I18N>No Data to Display</I18N>
                                </td>
                            </tr>
                        )}
                    </MDBTableBody>
                </MDBTable>
            </MDBCard>
            <MDBCard className="pager">
                <MDBCardBody>
                    <Pager total={total} limit={props.limit} onPage={load.current} search={props.query} />
                </MDBCardBody>
            </MDBCard>
        </div>
    );
});
