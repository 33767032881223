import React from "react";
import { useCrumbs } from "../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import useLocale from "../util/i18n";
import { InventoryData, InventoryItemData, deleteInventory, getInventory, getInventoryItems } from "../api/inventory";
import { MDBBtn, MDBCard, MDBCardHeader, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter } from "mdb-react-ui-kit";
import { useSearchParams } from "react-router-dom";
import Pager from "../component/pager";
import { parseEpc, parseSku } from "../util/misc";
import { usePayload } from "../util/token";
import Dispatch from "./stock/Dispatch";
import Toast from "../component/toast";

export default function Dashboard() {
    let [inventories, setInventories] = React.useState<Array<InventoryData>>([]);
    let [total, setTotal] = React.useState(0);
    let [search, setSearch] = useSearchParams();
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    let [payload] = usePayload();
    React.useEffect(() => {
        search.delete('version');
        getInventory(search).then(response => {
            if (typeof response !== 'string') {
                setInventories(response.data);
                setTotal(response.total);
            }
        });
    }, [search]);
    React.useEffect(() => {
        setCrumbs(['Dashboard']);
    }, []);
    return payload?.admin == 3 ? (
        <Dispatch />
    ) : (
        <>
            <Helmet prioritizeSeoTags>
                <title>{__('Dashboard')}</title>
            </Helmet>
            <div className="dashboard" data-searchable>
                {typeof inventories === 'undefined' ? (
                    <div className="loading"><span className="fa fa-spin fa-spinner" /></div>
                ) : (inventories.length ? inventories.map(inventory => (
                    <Inventory key={inventory.id.toString()} inventory={inventory} />
                )) : (
                    <div className="empty">
                        <span className="far fa-calendar-xmark" />
                        <span>{__('No Data to Display')}</span>
                    </div>
                ))}
            </div>
            {inventories.length ? (<Pager total={total} search={search} onPage={setSearch} />) : null}
            <Search />
        </>
    );
};

function Inventory(props: { inventory: InventoryData }) {
    let [open, setOpen] = React.useState(false);
    let [items, setItems] = React.useState<Array<InventoryItemData>>();
    let [__, lang] = useLocale();
    let { 1: setSearch } = useSearchParams();
    let doDelete = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (window.confirm(__('Are you sure to delete this? The deleted data cannot be recovered.'))) {
            deleteInventory(props.inventory.id).then(response => {
                setSearch(search => {
                    let cloned = new URLSearchParams(search);
                    cloned.set('version', (parseInt(cloned.get('version') || '0') + 1).toString());
                    return cloned;
                });
                Toast.show(response);
            }, error => Toast.show(error, 'danger'));
        }
    }, [props.inventory, __]);
    const ref = React.useRef<HTMLUListElement>(null);
    React.useEffect(() => {
        if (open && typeof items === 'undefined') {
            getInventoryItems({ id: props.inventory.id }).then(response => {
                typeof response !== 'string' && setItems(response);
                window.setTimeout(() => ref.current && (ref.current.style.height = 'auto'), 100);
            });
        }
    }, [open, items, props.inventory]);
    return (
        <MDBCard className="inventory">
            <MDBCardHeader>
                <div className="info">
                    {props.inventory.info ? (
                        <>
                            <span><span className="label">{__('Order ID')}</span><span className="value">{props.inventory.info.order_id || ''}</span></span>
                            <span><span className="label">{__('Shipment ID')}</span><span className="value">{props.inventory.info.shipment_id || ''}</span></span>
                            <span><span className="label">{__('Customer Name')}</span><span className="value">{props.inventory.info.customer_name || ''}</span></span>
                            <span><span className="label">{__('Customer ID')}</span><span className="value">{props.inventory.info.customer_id || ''}</span></span>
                        </>
                    ) : null}
                </div>
                <div className="text-nowrap">
                    <span className="time">
                        {Intl.DateTimeFormat(lang, { dateStyle: 'medium', timeStyle: 'short', hour12: false }).format(props.inventory.created_at * 1000)}
                    </span>
                    <MDBBtn className="toggler" type="button" color="link" onClick={setOpen.bind(null, !open)}>
                        <span className="fa fa-angle-down" style={{ transform: 'rotate(' + (open ? '180deg' : 0) + ')' }} />
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <ul className="card-body" ref={ref} style={open ? { height: ((ref.current?.scrollHeight || 0) + 20) + 'px', paddingTop: '10px', paddingBottom: '10px' } : { height: '0px' }}>
                {typeof items === 'undefined' ? (
                    <li className="loading"><span className="fa fa-spin fa-spinner" /></li>
                ) : (items.length ? (
                    <>
                        {items.map(item => {
                            let code = parseEpc(item.sku), sku = parseSku(code);
                            return (
                                <li key={item.sku} title={code}>{sku}</li>
                            )
                        })}
                        <li className="btns">
                            <button type="button" onClick={doDelete}><span className="fa fa-trash-alt" /></button>
                        </li>
                    </>
                ) : (
                    <li className="empty">
                        <span className="far fa-calendar-xmark" />
                        <span>{__('No Data to Display')}</span>
                    </li>
                ))}
            </ul>
        </MDBCard>
    );
}

function Search() {
    let [open, setOpen] = React.useState(false);
    let [search, setSearch] = useSearchParams();
    let [value, setValue] = React.useState(search.get('q') || '');
    let [__] = useLocale();
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = new URLSearchParams(search);
        let data = new FormData(e.target as HTMLFormElement);
        if ((data.get('q') as string | null)?.length) {
            cloned.set('q', data.get('q') as string);
        } else {
            cloned.delete('q');
        }
        cloned.delete('page');
        cloned.delete('limit');
        setSearch(cloned);
        setOpen(false);
    }, [search, setSearch, setOpen]);
    React.useEffect(() => {
        let l = () => setOpen(true);
        document.addEventListener('search', l);
        return () => document.removeEventListener('search', l);
    }, [setOpen]);
    return (
        <MDBModal open={open} onClose={setOpen.bind(null, false)}>
            <MDBModalDialog>
                <MDBModalContent tag="form" onSubmit={onSubmit}>
                    <MDBModalBody>
                        <MDBInput type="search" name="q" value={value} onChange={e => setValue(e.target.value)} label={__('Search')} />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" color="secondary" onClick={setOpen.bind(null, false)}>{__('Cancel')}</MDBBtn>
                        <MDBBtn type="submit">{__('Search')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}
