import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import React from "react";
import Grid, { GridRef } from "../component/Grid";
import useLocale, { I18N } from "../util/i18n";
import Loading, { LoadingRef } from "./loading";
import { ResourceData, getResource, uploadResource } from "../api/resource";
import Toast from "./toast";
import env from "../env.json";
import { ListParams, getParamsFromFormData } from "../api/types";

export interface ResourceRef {
    show: (type: string, callback?: (item: any) => void) => void;
    hide: () => void;
}

interface ResourceProps {
    query: URLSearchParams;
    setQuery: (search: URLSearchParams) => void;
}

export type ResourceEventDetail = { mime?: string, callback: (item: any) => void };

export function getIconByMime(mime: string) {
    if (mime.startsWith('video')) {
        return (<span className="far fa-video" />);
    } else if (mime.startsWith('image')) {
        return (<span className="far fa-image" />);
    } else if (mime.startsWith('audio')) {
        return (<span className="far fa-audio" />);
    } else if (mime.startsWith('text')) {
        return (<span className="far fa-lines" />);
    }
    switch (mime) {
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return (<span className="far fa-word" />);
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return (<span className="far fa-excel" />);
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return (<span className="far fa-powerpoint" />);
        case 'application/pdf':
            return (<span className="far fa-pdf" />);
        case 'application/x-gzip':
        case 'application/x-bzip2':
        case 'application/x-xz':
        case 'application/x-7z-compressed':
        case 'application/x-compressed':
        case 'application/x-tar':
        case 'application/zip':
            return (<span className="far fa-zipper" />);
        case 'application/octet-stream':
            return (<span className="far fa-code" />);
        default:
            return (<span className="far fa-file" />);
    }
}

export default React.forwardRef<ResourceRef, ResourceProps>(function Resource(props, ref) {
    const [show, setShow] = React.useState(false);
    const mime = React.useRef('image');
    const grid = React.useRef<GridRef>(null);
    const [__] = useLocale();
    const columns = React.useMemo(() => ({
        id: {
            label: 'ID',
            className: 'w-1 text-center'
        },
        filename: {
            label: __('Name'),
            filterable: 'filename'
        },
        path: {
            label: __('Thumbnail'),
            className: 'w-1 text-center',
            format: (value: string, item: Record<string, any>) => item.mime.startsWith('video') ? (
                <video src={env.RES_URL + value} preload="metadata"></video>
            ) : (item.mime.startsWith('image') ? (
                <img src={env.RES_URL + value} alt="" />
            ) : getIconByMime(item.mime))
        }
    }), [__]);
    const _doUpload = React.useCallback((file: File | Blob) => {
        let data = new FormData();
        data.set('file', file);
        uploadResource(data).then(() => {
            grid.current?.reload(props.query);
        }, error => {
            Toast.show(error, 'danger');
        });
    }, [props.query]);
    const doUpload = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files?.length) {
            _doUpload(e.target.files[0]);
        }
    }, [props.query]);
    const onDragOver = React.useRef((e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
    });
    const onDrop = React.useCallback((e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        if (e.dataTransfer.files?.length) {
            _doUpload(e.dataTransfer.files[0]);
        }
    }, [props.query]);
    const doRemote = React.useCallback((e: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let url: string | null;
        if (url = window.prompt(__('Enter the full URL of the remote file which starts with https://'), 'https://')) {
            if (mime.current === 'image') {
                let i = new window.Image();
                loading.current?.show(true);
                i.onerror = function () {
                    loading.current?.hide();
                    Toast.show(__('Could not load $1 at the specified URL.').replace('$1', __('image')), 'danger');
                };
                i.onload = function () {
                    loading.current?.hide();
                    callback.current && callback.current(url);
                    setShow(false);
                };
                i.src = url;
            } else {
                callback.current && callback.current(url);
                setShow(false);
            }
        }
    }, [__]);
    const doLoad = React.useCallback(() => {
        let params: ListParams<Partial<ResourceData>> = getParamsFromFormData(props.query);
        params.filter || (params.filter = {});
        if (mime.current) {
            //@ts-ignore
            params.filter.mime = { like: mime.current + '/%' };
        }
        return getResource(params);
    }, [props.query]);
    const loading = React.useRef<LoadingRef>(null);
    const callback = React.useRef<(item: any) => void>();
    React.useImperativeHandle(ref, () => ({
        show: (t?: string, c?: (item: any) => void) => {
            mime.current = t || 'image';
            callback.current = c;
            setShow(true);
        },
        hide: () => setShow(false)
    }), []);
    React.useEffect(() => {
        let l = (e: CustomEvent<ResourceEventDetail>) => {
            mime.current = e.detail.mime || 'image';
            callback.current = e.detail.callback;
            setShow(true);
        };
        document.addEventListener<any>('resource', l);
        return () => document.removeEventListener<any>('resource', l);
    }, [setShow]);
    return (
        <MDBModal id="modal-resource" open={show} setOpen={setShow} tabIndex='-1'>
            <MDBModalDialog centered size="xl">
                <MDBModalContent onDragOver={onDragOver.current} onDrop={onDrop}>
                    <MDBModalHeader>
                        <MDBModalTitle>{__('Resource Explorer')}</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={setShow.bind(null, false)}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {
                            show ? (
                                <Grid
                                    ref={grid}
                                    columns={columns}
                                    renderBtns={() => (
                                        <>
                                            <label className="btn btn-info">
                                                <input type="file" hidden
                                                    accept={mime.current === 'image' ? 'image/jpeg,image/png,image/gif,image/webp,image/svg+xml' : (mime.current === 'video' ? 'video/mp4' : (mime.current || undefined))}
                                                    onChange={doUpload} />
                                                <I18N>Upload</I18N>
                                            </label>
                                            <MDBBtn type="button" color="danger" onClick={doRemote}><I18N>Remote File</I18N></MDBBtn>
                                        </>
                                    )}
                                    actions={[
                                        {
                                            label: __('Pick'),
                                            onClick: (item, row, e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                callback.current && callback.current(env.RES_URL + item.path);
                                                setShow(false);
                                            }
                                        }
                                    ]}
                                    doLoad={doLoad}
                                    query={props.query}
                                    setQuery={props.setQuery}
                                />
                            ) : null
                        }
                        <Loading ref={loading} />
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );

});

interface PickerProps {
    mime?: string;
    name?: string;
    value?: string;
    onChange?: (value: string) => void;
    className?: string;
    doDelete?: () => void;
}

export function Picker(props: PickerProps) {
    let [value, setValue] = React.useState('');
    const onClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(e => {
        e.stopPropagation();
        e.preventDefault();
        document.dispatchEvent(new CustomEvent<ResourceEventDetail>('resource', {
            detail: {
                mime: props.mime,
                callback: setValue
            }
        }));
    }, [props.mime]);
    React.useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);
    return value ? (
        <div className={'resource-picker ' + (props.className || '')}>
            <input type="hidden" name={props.name} value={value} />
            <img src={value || env.DEFAULT_IMG} alt="" />
            <div className="btns">
                <button type="button" onClick={onClick}>
                    <span className="far fa-pen-to-square" />
                </button>
                {props.doDelete ? (<button type="button" onClick={props.doDelete}>
                    <span className="far fa-trash-can" />
                </button>) : null}
            </div>
        </div>
    ) : (
        <button type="button" onClick={onClick} className={'resource-picker ' + (props.className || '')}>
            <input type="hidden" name={props.name} value={value} />
            <img src={value || env.DEFAULT_IMG} alt="" />
        </button>
    );
}
