import React from "react";
import useLocale from "../util/i18n";
import request from "../util/erp/unleashed";
import Select, { type OptionType } from "../component/select";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBInputGroup, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from "mdb-react-ui-kit";
import { useCrumbs } from "../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { getProducts, getPurchaseOrderItem, getSoldQty, getStockQty, Product, PurchaseOrderItem, type SkuQty } from "../api/erp";
import BigNumber from "bignumber.js";
import { type ListResponse } from "../api/types";
import Toast from "../component/toast";
import ExcelJS from "exceljs";

const months = [
    { value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' },
    { value: '5', label: '5' }, { value: '6', label: '6' }, { value: '7', label: '7' }, { value: '8', label: '8' },
    { value: '9', label: '9' }, { value: '10', label: '10' }, { value: '11', label: '11' }, { value: '12', label: '12' }
];

interface TableProps {
    supplier?: string;
    product_group: string;
    scpt: string;
    coft: string;
    stat_sale: string;
    fsi_1: string;
    fsi_2?: string;
    fsi_3?: string;
    fsi_4?: string;
    fsi_5?: string;
    fsi_6?: string;
    fsi_7?: string;
    fsi_8?: string;
    fsi_9?: string;
    fsi_10?: string;
    fsi_11?: string;
    fsi_12?: string;
}

export default function Forecast() {
    let { 0: params, 1: setParams } = React.useState<TableProps | null>(null);
    let { 0: fsi, 1: setFsi } = React.useState('3');
    let { 0: __ } = useLocale();
    const loadSupplier = React.useCallback(async () => {
        let options: Array<OptionType> = [];
        let response = await request('Suppliers');
        for (let i of (response.Items || [])) {
            options.push({
                value: i.Guid, label: i.SupplierName
            });
        }
        return options;
    }, []);
    const loadProductGroup = React.useCallback(async () => {
        let options: Array<OptionType> = [];
        let response = await request('ProductGroups');
        for (let i of (response.Items || [])) {
            options.push({
                value: i.GroupName, label: i.GroupName
            });
        }
        return options;
    }, []);
    let [setCrumbs] = useCrumbs();
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(e.target as HTMLFormElement);
        let itt = (new BigNumber(data.get('scpt') as string)).plus(data.get('coft') as string).div(30);
        if (itt.gt(fsi)) {
            Toast.show(__('The In Transit Time should be less than the time of Forecast Sales Increase.'));
            return;
        }
        let result: any = {};
        for (const [k, v] of data.entries()) {
            result[k as keyof TableProps] = v as string;
        }
        setParams(result);
    }, [setParams, fsi, __]);
    const doExport = React.useRef<() => void>(null);
    let onExport = React.useRef((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        doExport.current?.();
    });
    React.useEffect(() => {
        setCrumbs(['Forecast']);
    }, []);
    return (
        <div className="forecast">
            <Helmet>
                <title>{__('Forecast')}</title>
            </Helmet>
            <MDBCard className="hidden-print">
                <MDBCardBody tag="form" onSubmit={onSubmit}>
                    <MDBRow className="g-3">
                        <MDBCol size="12" lg="6" xxl="4" className="d-flex flex-column gap-3">
                            <h3>{__('Products')}</h3>
                            {/* <Select load={loadSupplier} name="supplier" label={__('Supplier')} /> */}
                            <Select load={loadProductGroup} name="product_group" label={__('Product Group')} />
                        </MDBCol>
                        <MDBCol size="12" lg="6" xxl="4" className="d-flex flex-column gap-3">
                            <h3>{__('In Transit Time')}</h3>
                            <MDBInput type="number" name="scpt" label={__('Supplier Current Production Time')} defaultValue={10} />
                            <MDBInput type="number" name="coft" label={__('Current Ocean Freight Time')} defaultValue={10} />
                        </MDBCol>
                        <MDBCol size="12" lg="6" xxl="4" className="d-flex flex-column gap-3">
                            <h3>{__('Statistical Period')}</h3>
                            <Select name="stat_sale" label={__('Sales')} options={months} defaultValue="12" />
                            <Select label={__('Forecasting Period')} options={months} value={fsi} onChange={setFsi} />
                        </MDBCol>
                        <MDBCol size="12">
                            <h3 className="mb-4">{__('Forecast Sales Increase')}</h3>
                            <MDBRow className="g-3">
                                {Object.keys((new Array(parseInt(fsi))).fill(true)).map(month => (
                                    <MDBCol key={'fsi_' + month} size="12" sm="6" lg="4" xxl="3">
                                        <MDBInputGroup textAfter="%">
                                            <MDBInput name={'fsi_' + (parseInt(month) + 1)} type="number" label={__('Next %d Month Increment').replace('%d', (parseInt(month) + 1).toString())} defaultValue={10} />
                                        </MDBInputGroup>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="12" className="text-center">
                            <MDBBtn type="submit" className="px-4">{__('Run')}</MDBBtn>
                            {params ? (<MDBBtn type="button" color="secondary" className="px-4 ms-3" onClick={onExport.current}>{__('Export')}</MDBBtn>) : null}
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            {params ? (<Table {...params} ref={doExport} />) : null}
        </div>
    );
}

interface Row {
    sku: string;
    size: number;
    w: number;
    h: number;
    d: number;
    price: number;
    aq?: number;
    aus?: BigNumber;
    mq?: BigNumber;
    op?: BigNumber;
    delivered_at?: number | null;
    ordered_at?: number;
}

function diff(month: number, last: number, year: number, days = 0): number {
    while (last <= 0) {
        last += 12;
    }
    switch (month) {
        case 4: case 6: case 9: case 11: days += 30; break;
        case 2: days += year % 100 !== 0 && year % 4 === 0 || year % 400 === 0 ? 29 : 28; break;
        default: days += 31;
    }
    return month === last ? days : days + diff(month - 1 === 0 ? 12 : month - 1, last, days);
}

const Table = React.forwardRef<() => void, TableProps>(function (props, ref) {
    let { 0: items, 1: setItems } = React.useState<Array<Row>>([]);
    let { 0: __, 1: lang } = useLocale();
    let formatter = React.useMemo(() => new Intl.NumberFormat(lang, { style: 'currency', currency: 'USD' }), [lang]);
    let opt = React.useCallback((item: Row) => {
        let result = new BigNumber(props.coft).times(86400);
        if (item.delivered_at) {
            result = new BigNumber(item.delivered_at);
        } else if (item.ordered_at) {
            result = result.plus(item.ordered_at || 0).plus(new BigNumber(props.scpt).times(86400));
        } else {
            return '-';
        }
        result = result.minus(Date.now() / 1000).div(86400);
        return parseFloat(result.toFixed(0, BigNumber.ROUND_CEIL));
    }, [props]);
    let itt = React.useMemo(() => (new BigNumber(props.coft || 10)).plus(props.scpt || 10).toNumber(), [props]);
    let head = React.useMemo(() => {
        return [
            { key: 'SKU', title: 'SKU', className: 'text-start' },
            { key: 'AQ', title: __('Available Quantity') },
            { key: 'OP', title: __('On Purchase Quantity') },
            { key: 'OPT(' + __('Days') + ')', title: __('On Purchase Arrival Time') + '(' + __('Days') + ')' },
            { key: 'AUS', title: __('Average Unit Sales') },
            { key: 'ITT(' + __('Days') + ')', title: __('In Transit Time') + '(' + __('Days') + ')' },
            { key: 'FSI', title: __('Forecast Sales Increase') },
            { key: 'FUS', title: __('Forecast Unit Sales') },
            { key: 'MQ', title: __('Minimal Quantity') },
            { key: 'EPOQ', title: __('Estimate Purchase Order Quantity') },
            { key: 'FOB/Unit Price', title: __('Unit Price') },
            { key: 'Line TT', title: __('Line Total') },
            { key: 'CBM', title: __('CBM') },
            { key: 'CBM TT', title: __('CBM Total') }
        ];
    }, [__]);
    let body = React.useMemo(() => {
        let result: Array<Array<{ value: string | number, title?: string, className?: string }>> = [];
        let year = (new Date()).getFullYear(), month = (new Date()).getMonth() + 1, days = diff(month, month, year);
        let t = new BigNumber(itt), i = 0, d = new BigNumber(0);
        for (let j = 0; ; j++) {
            let days = diff(month + j, month + j, year);
            if (t.gte(days)) {
                t = t.minus(days);
                i++;
            } else {
                d = t.div(days);
                break;
            }
        }
        for (let item of items) {
            let row: Array<{ value: string | number, title?: string, className?: string }> = [
                { value: item.sku, className: 'text-start' },
                { value: item.aq || 0 },
                { value: item.op?.toNumber() || 0 },
                { value: opt(item) },
                { value: parseFloat(item.aus?.toFixed(4) || '0') },
                { value: itt }
            ];
            let fsis: Array<BigNumber> = [], fsi = new BigNumber(0);
            for (let i = 1; i <= 12; i++) {
                if (!props['fsi_' + i as keyof TableProps]) {
                    break;
                }
                fsis[i - 1] = (i === 1 ? (item.aus || new BigNumber(0)).times(days) : fsis[i - 2]).times((new BigNumber(props['fsi_' + i as keyof TableProps] as string)).div(100).plus(1));
                fsi = fsi.plus(fsis[i - 1]);
            }
            let fus = new BigNumber(0);
            for (let j = 1; j <= i; j++) {
                fus = fus.plus(fsis[j - 1]);
            }
            fus = fus.plus(d.times(fsis[i]));
            let mq = item.mq?.times(itt);
            let epoq = fus?.plus(mq || 0).minus(item.aq || 0).minus(item.op || 0).integerValue(BigNumber.ROUND_CEIL);
            result.push(row.concat([
                { value: fsi?.toFixed(0, BigNumber.ROUND_CEIL) || '0' },
                { value: fus?.toFixed(0, BigNumber.ROUND_CEIL) || '0' },
                { value: mq?.toFixed(0, BigNumber.ROUND_CEIL) || '0' },
                { value: Math.max(0, epoq?.toNumber() || 0) },
                { value: formatter.format(item.price) },
                { value: formatter.format(Math.max(0, epoq?.times(item.price).toNumber() || 0)) },
                { title: 'W' + item.w + '"H' + item.h + '"D' + item.d + '"', value: parseFloat(item.size.toFixed(4)) },
                { value: Math.max(0, parseFloat(epoq?.times(item.size).toFixed(1) || '0')) },
            ]));
        }
        result.sort((a, b) => (b[b.length - 5].value as number) - (a[a.length - 5].value as number) || ((b[0].value as number) >= (a[0].value as number) ? 1 : -1));
        return result;
    }, [items, itt, opt, props, formatter]);
    React.useImperativeHandle(ref, () => (() => {
        const file = new ExcelJS.Workbook();
        const sheet = file.addWorksheet();
        let r = 1, c = 1;
        let row = sheet.getRow(r);
        for (const { title } of head) {
            row.getCell(c++).value = title;
        }
        for (const data of body) {
            let row = sheet.getRow(++r);
            c = 1;
            for (const { value } of data){
                row.getCell(c++).value = value;
            }
        }
        file.xlsx.writeBuffer().then(buffer => {
            let a = document.createElement('a');
            a.download = 'export.xlsx';
            a.href = URL.createObjectURL(new Blob([buffer]));
            document.body.append(a);
            a.click();
            URL.revokeObjectURL(a.href);
            a.remove();
        });
    }), [body, head]);
    React.useEffect(() => {
        (async () => {
            let ps = [], items: Record<string, Row> = {}, year = (new Date()).getFullYear(), month = (new Date()).getMonth() + 1, lt = (new Date(year + (month < 10 ? '-0' : '-') + month + '-1 00:00:00')).getTime() / 1000;
            ps.push(getProducts({ filter: { sku: { like: props.product_group + '%' } } }));
            ps.push(getStockQty({ filter: { sku: { like: props.product_group + '%' } } }));
            ps.push(getSoldQty({
                filter: {
                    sku: { like: props.product_group + '%' },
                    'order.completed_at': {
                        gte: (new BigNumber(lt)).minus((new BigNumber(86400)).times(diff(month - 1, month - parseInt(props.stat_sale ?? '12'), year))).integerValue(),
                        lt
                    }
                }
            }));
            ps.push(getSoldQty({
                filter: {
                    sku: { like: props.product_group + '%' },
                    'order.completed_at': {
                        gte: (new BigNumber(lt)).minus((new BigNumber(86400)).times(diff(month - 1, month - 3, year))).integerValue(),
                        lt
                    }
                }
            }));
            ps.push(getPurchaseOrderItem({ filter: { sku: { like: props.product_group + '%' }, 'order.status': 'Placed' }, limit: -1 }));
            let { 0: products, 1: stock, 2: sold, 3: mq, 4: opr } = await Promise.all(ps);
            if (typeof products !== 'string') {
                for (let i of (products as ListResponse<Product>).data) {
                    items[i.sku] = {
                        sku: i.sku, price: i.price, w: parseFloat(i.width.toString()), h: parseFloat(i.height.toString()), d: parseFloat(i.depth.toString()),
                        size: (new BigNumber(i.width)).times(i.height).times(i.depth).div('61023.74409473228395').toNumber()
                    };
                }
            }
            if (typeof stock !== 'string') {
                for (let i of (stock as ListResponse<SkuQty>).data) {
                    items[i.sku] ??= { sku: i.sku, price: 0, size: 0, w: 0, h: 0, d: 0 };
                    items[i.sku].aq = parseFloat(i.qty.toString());
                }
            }
            if (typeof sold !== 'string') {
                let days = diff(month - 1, month - parseInt(props.stat_sale ?? '12'), year);
                for (let i of (sold as ListResponse<SkuQty>).data) {
                    items[i.sku] ??= { sku: i.sku, price: 0, size: 0, w: 0, h: 0, d: 0 };
                    items[i.sku].aus = (new BigNumber(i.qty)).div(days);
                }
            }
            if (typeof mq !== 'string') {
                let days = diff(month - 1, month - 3, year);
                for (let i of (mq as ListResponse<SkuQty>).data) {
                    items[i.sku] ??= { sku: i.sku, price: 0, size: 0, w: 0, h: 0, d: 0 };
                    items[i.sku].mq = (new BigNumber(i.qty)).div(days);
                }
            }
            if (typeof opr !== 'string') {
                for (let i of (opr as ListResponse<PurchaseOrderItem>).data) {
                    items[i.sku] ??= { sku: i.sku, price: 0, size: 0, w: 0, h: 0, d: 0 };
                    items[i.sku].op = (items[i.sku].op ?? new BigNumber(0)).plus(i.ordered_qty);
                    items[i.sku].ordered_at = Math.min((items[i.sku].ordered_at || Number.MAX_SAFE_INTEGER), i.ordered_at);
                    items[i.sku].delivered_at = i.delivered_at ? Math.min((items[i.sku].delivered_at || Number.MAX_SAFE_INTEGER), i.delivered_at) : (items[i.sku].delivered_at || null);
                }
            }
            setItems(Object.values(items));
        })();
    }, [props, setItems]);
    return items.length ? (
        <MDBCard className="mt-4">
            <MDBCardBody>
                <MDBTable bordered hover responsive small className="text-center">
                    <MDBTableHead>
                        <tr>
                            {head.map(h => (
                                <th key={h.key} className={h.className}>{h.key}<MDBTooltip wrapperClass="fa fa-info-circle thead-tip" wrapperProps={{ color: 'link' }} title={h.title} /></th>
                            ))}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {body.map(row => (
                            <tr key={row[0].value}>
                                {row.map((cell, i) => (
                                    <td key={row[0].value + '-' + i} title={cell.title} className={cell.className}>{cell.value}</td>
                                ))}
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    ) : (
        <div className="loading bg-white mt-4 text-center p-3"><span className="fa fa-spin fa-spinner fa-4x" /></div>
    );
});
